import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Menu from "../components/Menu"

import { ThemeProvider } from 'styled-components'
import General from '../styles/theme'

import NotFound from '../components/NotFound'

const NotFoundPage = () => (
  <ThemeProvider theme={General}>
    <Layout>
      <SEO title="404: Not found" />
      <Menu/>
      <NotFound/>
    </Layout>
  </ThemeProvider>
)

export default NotFoundPage
