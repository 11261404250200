import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import * as S from "./styled"
import { withTrans } from "../../i18n/withTrans"

const NotFound = ({ t, i18n }) => (
  <S.Container>
    <S.Title>404</S.Title>
    <S.Message>Ops! <br/>{t("notFound.title")}</S.Message>
    <AniLink to="/">
      <S.Button>{t("notFound.button")}</S.Button>
    </AniLink>
  </S.Container>
)

export default withTrans(NotFound)
