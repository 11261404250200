import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h4`
  margin-top: 50px;
  font-size: 200px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
`

export const Message = styled.p`
  display: block;
  font-size: 25px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  text-align: center;
  line-height: 40px;
  color: ${props => props.theme.colors.grey};
`
export const Button = styled.button`
  margin-top: 30px;
  width: 179px;
  height: 44px;
  background-color: ${props => props.theme.colors.info};
  color: ${props => props.theme.colors.primary};
  font-size: 16px;
  font-family: "Josefin Sans", sans-serif;
  border-radius: 26px;
`
